import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { SectionProps } from '../../utils/SectionProps'
import Input from '../elements/Input'

const propTypes = {
  ...SectionProps.types,
  split: PropTypes.bool,
}

const defaultProps = {
  ...SectionProps.defaults,
  split: false,
}

const Cta = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  split,
  ...props
}) => {
  const outerClasses = classNames(
    'cta section center-content-mobile reveal-from-bottom',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  )

  const innerClasses = classNames(
    'cta-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider',
    split && 'cta-split'
  )

  return (
    <section {...props} className={outerClasses}>
      <div id="contact-us" className="container">
        <div className={innerClasses}>
          <div className="cta-slogan">
            <h3 className="m-0">
              Email us Today. <br />
              Our dedicated support team is here to help with any form of
              request <br /> info@softinvarcel.com
            </h3>
          </div>
          <div className="cta-action">
            <p>Ghana : (233) 20 199 4916</p>
            <p>Nigeria : (234) 803 622 9802</p>
            <p>Benin : (229) 61 86 9380</p>
            <p>South Africa : (27) 78 172 3389</p>
            <p>DRC : (243) 97 266 5957</p>

            <svg width="16" height="12" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M9 5H1c-.6 0-1 .4-1 1s.4 1 1 1h8v5l7-6-7-6v5z"
                fill="#376DF9"
              />
            </svg>
          </div>
        </div>
      </div>
    </section>
  )
}

Cta.propTypes = propTypes
Cta.defaultProps = defaultProps

export default Cta
