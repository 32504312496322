import React from 'react'
// import sections
import Hero from '../components/sections/Hero'
import FeaturesTiles from '../components/sections/FeaturesTiles'
import FeaturesSplit from '../components/sections/FeaturesSplit'
import FeaturesSplit1 from '../components/sections/FeatureSplit1'

import Testimonial from '../components/sections/Testimonial'
import Cta from '../components/sections/Cta'

const Home = () => {
  return (
    <>
      <Hero className="illustration-section-01" />
      <FeaturesSplit1
        invertMobile
        topDivider
        imageFill
        className="illustration-section-02"
      />

      <FeaturesTiles />

      <Cta split />
    </>
  )
}

export default Home
